<template>
  <section class="agents">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="row justify-content-center">
        <div class="agents-content">
          <div class="row mb-4 align-items-center justify-content-between">
            <div>
              <h2>Agentes</h2>
              <p>
                Visualiza la actividad de tus agentes en los últimos 30 días.
              </p>
            </div>
            <div class="box-buttons d-flex align-items-center">
              <FadeTransition :duration="200">
                <div v-if="selected.length > 0 && view_permissions.delete == true">
                  <span @click="deleteAgents" class="btn-text text-danger mx-2 mr-3">
                    <i class="fas fa-times" />Desvincular</span>
                  </div>
              </FadeTransition>
              <!-- <router-link
                type="button"
                class="btn-select bg_light-gray sm my-1 mr-3"
                to="/role/list"
                >Roles del chatbot
              </router-link> -->
              <button
                type="button"
                class="btn-select bg_light-gray sm my-1 mr-3"
                @click="asociateAgent"
                :disabled="view_permissions.edit != true"
              >
                Asociar agente
                <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes acceso a este módulo.</span>
              </button>
              <button class="btn-select static sm my-1" @click="createAgent()" :disabled="view_permissions.add != true">
                <i class="fas fa-plus" />Crear agente
                <span class="tooltip_text-sm for_disabled" v-if="view_permissions.add != true">Lo sentimos, no tienes acceso a este módulo.</span>
              </button>
              <router-link v-if="view_permissions.read == true" to="/historial-estado-agentes" class="btn-select static sm my-1 ml-3">
                <i class="fas fa-signal"></i> Seguimiento
              </router-link>
            </div>
          </div>
          <div v-if="usersResume.length > 0" class="card-table">
            <base-table class="tabla__agentes" :data="usersResume" ref="table">
              <template slot="columns">
                <th v-if="view_permissions.delete == true">
                  <input
                    type="checkbox"
                    v-model="isCheckAll"
                    @click="checkAll"
                  />
                </th>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Categoría</th>
                <th>Rol</th>
                <!-- <th>Asignación automática</th>
                <th>Derivar conversación</th> -->
                <th>Última conexión</th>
                <th>Última intervención</th>
                <th>Intervenciones</th>
                <th>Calificaciones</th>
              </template>

              <template slot-scope="{ row }">
                <td v-if="view_permissions.delete == true">
                  <input
                    type="checkbox"
                    :id="row.id"
                    :value="row"
                    v-model="selected"
                    @change="updateCheckall"
                  />
                </td>
                <td
                  scope="row"
                  @mouseover="hoverAction = row.relation_id"
                  @mouseleave="hoverAction = false"
                >
                  <!-- v-if="![64, 749].includes(row.bot_id) && !row.user.email.includes('databot.cl')" -->
                  <div class="d-flex align-items-center agent">
                    <img
                      class="avatar avatar-sm rounded-circle mr-3"
                      alt="foto usuario"
                      :src="
                        row.image
                          ? row.image
                          : '/img/brand/0-perfil-agente.png'
                      "
                    />
                    <b>{{ row.name }}</b>
                    <div :class="{ acciones: true, active: hoverAction == row.relation_id }">
                      <router-link v-if="view_permissions.edit == true" :to="'/agente/edit/' + row.relation_id">
                        <span class="i-btn i-btn-sm i-edit"></span>
                      </router-link>
                    </div>
                  </div>
                </td>
                <td>{{ row.email }}</td>
                <td>
                  <div class="custom-width" v-if="row.category && row.category.length > 0">
                    <span v-for="(cate, index) in row.category" :key="index" class="text-state">
                      {{ cate }}
                    </span>
                  </div>
                  <span v-else>Sin Asignar</span>
                </td>
                <td class="capitalize">{{ row.role }}</td>
                <!-- <td>
                  {{
                    row.automatic_assignments ? 'Habilitado' : 'Deshabilitado'
                  }}
                </td>
                <td>{{ row.assignments ? 'Habilitado' : 'Deshabilitado' }}</td> -->
                <td v-if="row.last_activity == ''">
                  -
                </td>
                <td v-else>
                  <img
                    class="icon-date"
                    src="/img/icons/19-calendar.svg"
                    alt="Calendario"
                  />
                  {{ $moment(row.last_activity).format('DD/MM/YYYY') }}
                  <img
                    class="icon-hour"
                    src="/img/icons/20-clock.svg"
                    alt="Hora"
                  />
                  {{ $moment(row.last_activity).format('hh:mm a') }}
                </td>
                <td v-if="row.last_intervention == 'sin conversaciones'">
                  -
                </td>
                <td v-else>
                  <img
                    class="icon-date"
                    src="/img/icons/19-calendar.svg"
                    alt="Calendario"
                  />
                  {{ $moment(row.last_intervention).format('DD/MM/YYYY') }}
                  <img
                    class="icon-hour"
                    src="/img/icons/20-clock.svg"
                    alt="Hora"
                  />
                  {{ $moment(row.last_intervention).format('hh:mm a') }}
                </td>
                <td>
                  {{ row.quantity_conversations }}
                </td>
                <td>
                  <div class="stars_box" v-if="row.califications > 0">
                    <img
                      v-for="(el, index) in Math.floor(row.califications * 1)"
                      :key="index"
                      class="stars"
                      src="/img/rating/0-star.svg"
                      alt="Star"
                    />
                    <img
                      v-if="row.califications % 1 != 0"
                      class="stars"
                      src="/img/rating/0.2-star-3.svg"
                      alt="Half-Star"
                    />
                    <span class="rating-agent">
                      ({{ Number(row.califications).toFixed(1) }}/5)
                    </span>
                  </div>
                  <div v-else>
                    -
                  </div>
                </td>
              </template>
            </base-table>
          </div>
          <div v-else class="info_box">
            <p class="text-center text_sm mr-2">
              Aún no tienes agentes registrados para gestionar tus chatbots.
              ¡Anímate a crearlos!
            </p>
            <span @click="createAgent()" class="btn-dash btn-dash-sm mx-0"
              >+</span>
          </div>
          <div class="col-lg-4 px-0 my-5">
            <router-link class="btn-text" to="/dashboard">&lt;- Volver</router-link>
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
    <AsociarAgente
      :show="showAsociarAgente"
      :usersResume="usersResume"
      :all_roles="all_roles"
      @addAgentTableIndex="addAgentTableIndex"
      @close="showAsociarAgente = false"
    />
  </section>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Table, Input } from 'buefy';
import dashboard_api from '@/dashboard_api.js';
import AsociarAgente from './AssociateAgent';
import { FadeTransition } from "vue2-transitions";
import axios from "axios";
import environment from "../../environment";

Vue.use(Table);
Vue.use(Input);

export default {
  components: {
    AsociarAgente,
    FadeTransition
  },
  data() {
    return {
      loading: true,
      agentes: [],
      showAsociarAgente: false,
      max_agent: 0,
      hoverAction: false,
      usersResume: [],
      selected: [],
      isCheckAll: false
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(['user', 'bot', 'role', 'active_view', 'selected_bot', 'view_permissions', 'all_roles']),
  },
  // Obtener los usuarios relacionados a un bot
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getResumeAgent(val);
      },
    },
  },
  methods: {
    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.selected = [];
      if (this.isCheckAll) {
        for (let key in this.usersResume) {
          this.selected.push(this.usersResume[key]);
        }
      }
    },
    updateCheckall() {
      if (this.selected.length == this.usersResume.length) this.isCheckAll = true;
      else this.isCheckAll = false;
    },
    async getResumeAgent(bot) {
      let agents = [];
      if (this.selected_bot.is_databot_live_3) {
        try {
          const payload = { from: "", to: "", bot_id: bot.id, agent_ids: agents.map(el => el.id)};
          agents = (await axios.post(`${environment.conversations}/agent_activities/agent_details`, payload)).data;
        } catch (err) {
          console.log("agent_details err->", err);
        } finally {
          await this.getPlanAgents(bot.id);
          this.usersResume = agents.filter(el => el.role != 'propietario');
          this.loading = false;
        }
      } else {
        try {
          agents = (await dashboard_api.get(`/userbot/resume_agents_for_bot/${bot.id}`)).data;
        } catch (error) {
          console.log("resume_agents_for_bot err->", error);
        } finally {
          await this.getPlanAgents(bot.id);
          this.usersResume = agents.filter(el => el.role != 'propietario');
          this.loading = false;
        }
      };
    },
    addAgentTableIndex(agent) {
      this.usersResume.push(agent);
      this.showAsociarAgente = false;
    },
    asociateAgent() {
      if (this.usersResume.length >= this.max_agent) {
        this.$swal({
          icon: 'error',
          title:
            'Lo sentimos, pero a superado la cantidad máxima de agentes del plan',
        });
      } else this.showAsociarAgente = true;
    },
    createAgent() {
      if (this.usersResume.length >= this.max_agent) {
        this.$swal({
          icon: 'error',
          title:
            'Lo sentimos, pero a superado la cantidad máxima de agentes del plan',
        });
      } else this.$router.push('/agente/nuevo');
    },
    // Borrar Agentes Nuevo
    deleteAgents() {
      const self = this;
      self
        .$swal({
          title: '¿Estás seguro?',
          text: 'No podrás revertir esta acción',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'Desvincular',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        })
        .then(result => {
          if (result.isConfirmed) {
            self.$swal({
              icon: 'info',
              title: 'Desvinculando agente',
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            self.$swal.showLoading();
            for (const userbot of self.selected) {
              axios
                .delete(`${ environment.url_redis }/delete_counter_agent/${ self.selected_bot.id }/${ userbot.id }`)
                .then(res => {
                  dashboard_api
                    .delete(`/userbot/userbotdestroy/${userbot.relation_id}`)
                    .then(response => {
                      let contador = 0;
                      self.usersResume.forEach(element => {
                        if (element.relation_id == userbot.relation_id) {
                          self.usersResume.splice(contador, 1);

                          self.$swal({
                            icon: 'success',
                            title: 'Agente desvinculado',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            timer: 1000,
                          });
                        }
                        contador += 1;
                      });
                    });
                })
            }
          }
        })
        .then(res => (self.selected = []));
    },
    // Borrar un agente
    // deleteUserbot(userbot) {
    //   const self = this;
    //   self
    //     .$swal({
    //       title: "¿Estás seguro?",
    //       text: "No podrás revertir esta acción",
    //       icon: "warning",
    //       showConfirmButton: true,
    //       confirmButtonText: "Desvincular",
    //       showCancelButton: true,
    //       cancelButtonText: "Cancelar"
    //     })
    //     .then(result => {
    //       if (result.isConfirmed) {
    //         self.$swal({
    //           icon: "info",
    //           title: "Desvinculando agente",
    //           showConfirmButton: false,
    //           allowOutsideClick: false
    //         });
    //         self.$swal.showLoading();
    //         dashboard_api
    //           .delete(`/userbot/userbotdestroy/${userbot.relation_id}`)
    //           .then(response => {
    //             let contador = 0;
    //             self.usersResume.forEach(element => {
    //               if (element.relation_id == userbot.relation_id) {
    //                 self.usersResume.splice(contador, 1);

    //                 self.$swal({
    //                   icon: "success",
    //                   title: "Agente desvinculado",
    //                   showConfirmButton: false,
    //                   allowOutsideClick: false,
    //                   timer: 1000
    //                 });
    //               }
    //               contador += 1;
    //             });
    //           });
    //       }
    //     });
    // },
    async getPlanAgents(bot_id) {
      this.max_agent = (
        await dashboard_api.get(`bot/bot_agents/${bot_id}`)
      ).data.agents;
      console.log('max_agent->', this.max_agent);

    },
  },
};
</script>

<style lang="scss" scoped>
.agents {
  width: 100%;

  &-content {
    width: 87vw;

    @media (max-width: 1600px) {
      width: 100%;
      max-width: 80vw;
    }
  }
  .card-table {
    border: 1px solid #e8e8e8;
    border-radius: 1rem;
    background-color: #fff;
    background-color: #fff;
    max-height: 480px;
    overflow: scroll !important;
    max-width: 100vw;
    width: 87vw;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
    @media (max-width: 1600px) {
      width: fit-content;
      max-width: 80vw;
    }
  }
  .box-buttons {
    justify-content: space-around;

    @media (max-width: 1080px) {
      width: 100%;
      margin: 0 auto;
      padding-left: 1rem;
    }
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
  .info_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
  }
  .stars {
    width: 0.8rem;
    margin: 0 0.15rem;
  }
}
.rating-agent {
  letter-spacing: 1px;
  font-size: 12px;
}
.acciones {
  visibility: hidden;
}
.active {
  visibility: visible;
}
.card-table .tabla__agentes {
  padding: 4px;
  border-radius: .5rem;
}
.table td,
.table th {
  white-space: nowrap;
  padding: 1rem;
}

input[type='checkbox'],
textarea[type='checkbox'],
.input[type='checkbox'] {
  width: 1.25em;
  height: 1.25em;
}
.icon-date {
  width: 14px;
  margin: -1px 2px 2px 0;
}
.icon-hour {
  width: 14px;
  margin: -1px 2px 2px 10px;
}
.custom-width {
  display: flex;
  flex-wrap: nowrap;

  .text-state {
    margin-right: .5rem !important;
  }
}
</style>
