import Vue from 'vue';
import environment from '@/environment.js';
import { io } from 'socket.io-client';
import dashboard_api from '@/dashboard_api.js';
import store from '@/store/store.js';
import {
  botSiccId,
  getIndustriesFromLocalStorage,
  getCompaniesFromLocalStorage,
  capitalizeFirstLetter,
  scrollBottom,
} from '@/utils/utils';
import filtersDatabotLive from '@/utils/filtersDatabotLive.js';
import Swal from 'sweetalert2';

// CAMBIAR POR VARIABLE DE ENTORNO LOS ENVIRONMENT.JS
const socket = io('https://databot-chatbot-backend.herokuapp.com');

/** SOCKET FOR NEW FLOW DATABOTLIVE 3 */

socket.on('NEW_CONVERSATION', async (data) => {
  console.log('NEW_CONVERSATION-->', data);
  const conversation = data;
  const userId = store.state.user.id;
  const selectedBot = store.state.selected_bot;
  // Only add conversation if it belongs to currently selected bot
  if (selectedBot.id === conversation.bot_id) {
    store.commit('conversationsModule/addConversation', {
      ...conversation,
      isUserOnline: true,
    });
    const autorizedBotsNotifications = [64, 7, 456]; // solo bots autorizados por pruebas de notificaciones
    if (
      data.conversation_id &&
      autorizedBotsNotifications.includes(data.bot_id)
    ) {
      store.dispatch('notificationsModule/newConversation', conversation);
    }
    // notify user
    Swal.fire({
      toast: true,
      html: `<span><span class="notification_number mr-2">1</span> Nueva conversación</span>`,
      position: 'top-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: false,
    });
    store.commit('setNewMessage', true);
    store.commit('conversationsModule/increaseCountersByState', {
      state: conversation.state,
      qty: 1,
    });
    Vue.prototype.$playSoundNotification('n_conversation', userId);
  }
});

socket.on('NEW_MESSAGE', async (data) => {
  const {
    botId,
    conversation_id,
    mid,
    text,
    from,
    agent,
    formattedLastMessage,
    temporal_id,
  } = data;
  const userId = store.state.user.id;
  const conversationModule = store.state.conversationsModule;
  const { selectedConversation } = conversationModule;
  const conversation =
    store.getters['conversationsModule/getConversationById'](conversation_id);
  const conversationIndex = conversationModule.conversations.findIndex(
    (conversation) => conversation.id == conversation_id,
  );
  console.log('socket conversation->', conversation);

  const currentBot = store.state.selected_bot.id;
  let messageExist = null;
  if (currentBot == botId) {
    if (selectedConversation && selectedConversation.id === conversation_id) {
      console.log('insertando...');
      if (temporal_id) {
        messageExist =
          store.getters['conversationsModule/getMessageByTemporalId'](
            temporal_id,
          );
      }
      if (!messageExist) {
        store.commit('conversationsModule/addMessage', { ...data });
      } else {
        // update its mid
        store.commit('conversationsModule/updateMessageByTemporalId', {
          temporalId: temporal_id,
          data: { ...messageExist, mid },
        });
      }
      // scroll bottom
      scrollBottom();
    } else if (conversation && from === 'Cliente') {
      if (!conversation.pending_messages_count) {
        conversation.pending_messages_count = 0;
      }
      conversation.pending_messages_count += 1;
    }
    if (conversation && from === 'Cliente') {
      // update last message
      conversation.last_message = formattedLastMessage;
      conversation.last_message_date = new Date().toUTCString();

      store.commit('conversationsModule/update', {
        id: conversation_id,
        data: conversation,
      });
      store.commit('setNewMessage', true);
    }
  }
  // notify user if its an active message
  if (from === 'Cliente' && agent && agent.id == userId) {
    Vue.prototype.$playSoundNotification('n_databotlive', userId);
  }
});

socket.on('DERIVE_CONVERSATION', async (data) => {
  console.log('🐞 NEW DERIVED CONVERSATION:', data);
  const userId = store.state.user.id;
  const { target_user_id, transfer_reason, target_agent, conversation } = data;
  const selectedBot = store.state.selected_bot;
  if (userId == target_user_id) {
    // notify user with swal text
    Swal.fire({
      title: 'Te derivaron una conversación',
      text: `El usuario ${
        target_agent.user?.name || ''
      } te derivó una conversación con motivo: ${transfer_reason}. Revisa tu sección de "Tomadas"`,
    });
    // notify user
    // Swal.fire({
    //   toast: true,
    //   html: `<span><span class="notification_number mr-2">1</span> Nueva conversación</span>`,
    //   position: 'top-end',
    //   showConfirmButton: false,
    //   timer: 4000,
    //   timerProgressBar: false,
    // });
    store.commit('setNewMessage', true);
    Vue.prototype.$playSoundNotification('n_conversation', userId);
    // only if conversation belongs to selected bot
    if (selectedBot.id === conversation.bot_id) {
      // update state counters
      store.dispatch('conversationsModule/countStates', {
        botId: conversation.bot_id,
        userId: target_user_id,
      });
      // in case agent current view is 'taken, add conversation...'
      if (store.state.conversationsModule.selectedState === 'taken') {
        store.commit('conversationsModule/addConversation', conversation);
      }
    }
  } else {
    console.log('Agents are not the same...');
  }
});

socket.on('UPDATE_CONVERSATION_CARD', async (conversation) => {
  console.log('log front socket');

  const { id, category, name, lead_id, state, bot_id } = conversation;
  console.log('🐞 LOG HERE id conversation UPDATE CARD:', id);
  let { botIds } = store.state.user;
  let hasBotId = botIds.includes(bot_id);
  console.log('Los bot ids: ', botIds);
  console.log('🐞 LOG HERE hasBotId:', hasBotId);
  let selectedBot = store.state.selected_bot;
  if (selectedBot.id != bot_id) {
    console.log(
      'Los bots no coinciden socket UPDATE_CONVERSATION_CARD: ',
      selectedBot.id,
      bot_id,
    );
    return;
  }
  let storeConversation =
    store.getters['conversationsModule/getConversationById'](id);
  let previousConversationState;
  console.log('state from conversation', state);

  let newConversationState = state;
  if (storeConversation) {
    previousConversationState = storeConversation.state;
    // in case previous and current convrsation state is differente, update counters
    // if (previousConversationState !== newConversationState) {
    //   store.commit('conversationsModule/increaseCountersByState', {
    //     state: previousConversationState,
    //     qty: -1,
    //   });
    //   store.commit('conversationsModule/increaseCountersByState', {
    //     state: newConversationState,
    //     qty: 1,
    //   });
    // }
    // update all storeConversation with payload
    storeConversation = { ...storeConversation, ...conversation };
    if (category) storeConversation.category = category;
    if (lead_id) storeConversation.lead_id = lead_id;
    if (!storeConversation.custom) storeConversation.custom = {};
    if (name) {
      storeConversation.lead_name = name;
      storeConversation.custom.name = name;
    }
    store.commit('conversationsModule/update', {
      id,
      data: storeConversation,
    });
  } else {
    console.log('Conversation doesnt exist..., add it', conversation);
    // in case conversation state doesnt exist, add it to store
    if (hasBotId && newConversationState !== 'taken') {
      console.log('Adding conversation...', conversation);
      console.log('Adding conversation state ...', newConversationState);
      store.commit('conversationsModule/increaseCountersByState', {
        state: newConversationState,
        qty: 1,
      });
      conversation.pending_messages_count = 1;
      store.commit('conversationsModule/addConversation', {
        ...conversation,
        isUserOnline: true,
      });
    }
  }
});
socket.on('PUSH_PENDING_CONVERSATION', async (data) => {
  const { conversationId, botId, hasAutoAssignAgent, agentId } = data;

  const conversation =
    store.getters['conversationsModule/getConversationById'](conversationId);
  const selectedState = store.state.conversationsModule.selectedState;
  const selectedBot = store.state.selected_bot;
  if (selectedBot.id != botId) {
    console.log('Los bots no coinciden: ', selectedBot.id, botId);
    return;
  }
  let stateToCheck = 'pending';
  if (hasAutoAssignAgent) {
    stateToCheck = 'taken';
  }

  if (conversation) {
    console.log('exist...');
    conversation.state = stateToCheck;
    store.commit('conversationsModule/update', {
      id: conversationId,
      data: conversation,
    });
  } else {
    console.log('Conversation pending doesnt exist...');
  }
  let userId = store.state.user.id;
  if (!agentId || agentId == userId) {
    if (selectedState !== stateToCheck) {
      // in case selected state is not pending, decrease counter
      store.commit('conversationsModule/increaseCountersByState', {
        state: selectedState,
        qty: -1,
      });
    }
    // increase counter to pending state
    store.commit('conversationsModule/increaseCountersByState', {
      state: stateToCheck,
      qty: 1,
    });
    let activateSound = await activateSoundNotification(
      botId,
      userId,
      {},
      'SOUND_NEW_PENDING_CONVERSATION',
    );
    if (activateSound) {
      Vue.prototype.$playSoundNotification('n_pending_conversation', userId);
    }
  }
});

socket.on('USER_ONLINE_STATUS', async (data) => {
  const { conversationId, status } = data;
  console.log('El status: ', conversationId, data, status);
  const conversation =
    store.getters['conversationsModule/getConversationById'](conversationId);
  if (conversation) {
    conversation.isUserOnline = status;
    store.commit('conversationsModule/update', {
      id: conversationId,
      data: { ...conversation, isUserOnline: status },
    });
  }
});

socket.on('DISCONNECT_CONVERSATION', async (data) => {
  const { conversationId, state } = data;
  store.dispatch('conversationsModule/deleteConversationAndDecreaseCounter', {
    id: conversationId,
    state,
  });
});
/** LEGACY SOCKETS */

socket.on('CONNECTED', async (data) => {
  console.log('CONECTADO');
  const roomToken = store.state.roomToken;
  // check if bot ids are in store
  let { botIds } = store.state.user;
  if (!botIds || !botIds.length) {
    const response = await dashboard_api.post('/bot/list_bot_ids_by_user', {
      user_id: store.state.user.id,
    });
    botIds = response.data;
    store.commit('setBotIdsToLocalStorage', botIds);
  }
  for (const botId of botIds) {
    socket.emit('AUTO_RECONNECT', {
      botId,
    });
  }
  // reconectando mediante room
  if (roomToken) {
    socket.emit('AUTO_RECONNECT', {
      roomToken,
    });
  }
});

socket.on('SOUND_NOTIFICATION_NEW_ACTIVE_CHAT_MESSAGE', async (data) => {
  const userId = store.state.user.id;
  const { botId } = data;
  const { from } = data;
  const activeChats = store.state.activeChatRooms;
  let activate = false;
  let activateSound = await activateSoundNotification(
    botId,
    userId,
    data.leadCustomData,
    'SOUND_NOTIFICATION_NEW_ACTIVE_CHAT_MESSAGE',
  );
  if (from === 'Cliente' && activateSound) {
    if (activeChats.length > 0) {
      if (
        activeChats.findIndex(
          (activeChat) => activeChat.room_token == data.room,
        ) > -1
      ) {
        activate = true;
      }
    } else {
      const chat_rooms = await dashboard_api.get(
        `/chat_rooms_by_user/${userId}/${store.state.active_view || 0}/${
          store.state.selected_bot.id
        }`,
      );
      const activeChatsDB = chat_rooms.data.filter(
        (chat) => chat.agent_id == userId,
      );
      store.commit('setActiveChatRooms', activeChatsDB);
      if (
        activeChatsDB.findIndex(
          (activeChat) => activeChat.room_token == data.room,
        ) > -1
      ) {
        activate = true;
      }
    }
    if (activate) Vue.prototype.$playSoundNotification('n_databotlive', userId);
  }
});

// notificacion de sonido para nueva conversacion
socket.on('PUSH_DATA', async ({ id, botId, reconnect }) => {
  if (!reconnect) {
    // no debe sonar si se trata de una reconeccion actualizacion pagina
    const userId = store.state.user.id;
    let activateSound = await activateSoundNotification(
      botId,
      userId,
      {},
      'PUSH_DATA',
    );
    if (id && activateSound) {
      Vue.prototype.$playSoundNotification('n_conversation', userId);
    }
  }
});

// notificacion de sonido para nuevo lead
socket.on('SOUND_NOTIFICATION_NEW_LEAD', async ({ botId, leadCustomData }) => {
  const userId = store.state.user.id;
  let activateSound = await activateSoundNotification(
    botId,
    userId,
    leadCustomData,
    'SOUND_NOTIFICATION_NEW_LEAD',
  );
  if (activateSound) {
    Vue.prototype.$playSoundNotification('n_leads', userId);
  }
});

// notificacion de sonido para nueva conversacion pendiente
socket.on(
  'SOUND_NEW_PENDING_CONVERSATION',
  async ({ botId, leadCustomData }) => {
    const userId = store.state.user.id;
    let activateSound = await activateSoundNotification(
      botId,
      userId,
      leadCustomData,
      'SOUND_NEW_PENDING_CONVERSATION',
    );
    if (activateSound) {
      Vue.prototype.$playSoundNotification('n_pending_conversation', userId);
    }
  },
);

socket.on('LOAD_PRODUCTS', async (data) => {
  // STORE
  let upload = store.state.ecommerceAccount;
  upload.upload_progress = data.progress.toFixed(0);

  store.commit('setEcommerceAccount', upload);
});

function activateSoundNotification(botId, userId, leadCustomData = {}, event) {
  return new Promise(async (resolve, reject) => {
    const isUserLogged = store.state.user.id; // solo se debe hacer esto si usuario logeado
    if (isUserLogged) {
      botId = typeof botId === 'string' ? parseInt(botId) : botId; // validacion int
      const owner = store.state.active_view === 'my_bots';
      let activate = false;
      let { bots } = store.state;
      if (bots.length > 0) {
        if (bots.findIndex((bot) => bot.id == botId) > -1) {
          activate = true;
        }
      } else {
        const response = await dashboard_api.get(
          `/bot/list/${userId}?owner=${owner}`,
        );
        bots = response.data;
        // se agregan los bots al store
        store.commit('setActiveBots', bots);
        // se verifica que el botId de la nueva conversación con el bot perteneza a los botsDB del usuario
        if (bots.findIndex((bot) => bot.id == botId) > -1) {
          activate = true;
        }
      }
      // alguna condicion extra para decidir si emitir sonido o no
      // condicion SICC
      if (
        bots.findIndex((bot) => bot.id == botId) > -1 &&
        botId == botSiccId()
      ) {
        activate = siccCardBelongsFilter(leadCustomData);
      }
      // condicion filtros personalizados
      if (
        bots.findIndex((bot) => bot.id == botId) > -1 &&
        filtersDatabotLive.hasCustomFilters(botId)
      ) {
        activate = cardBelongsFilter(leadCustomData);
      }
      resolve(activate);
    } else {
      resolve(false);
    }
  });
}

function siccCardBelongsFilter(leadCustomData) {
  let activate = true;
  const { filter_nombre_industria, filter_nombre_empresa } = leadCustomData;
  // si es que no hay ningun filtro, sonar directamente
  if (
    getIndustriesFromLocalStorage().length > 0 ||
    getCompaniesFromLocalStorage().length > 0
  ) {
    if (
      getIndustriesFromLocalStorage().includes(filter_nombre_industria) &&
      getCompaniesFromLocalStorage().length == 0
    ) {
      activate = true;
    } else if (
      getIndustriesFromLocalStorage().includes(filter_nombre_industria) &&
      getCompaniesFromLocalStorage().includes(filter_nombre_empresa)
    ) {
      activate = true;
    } else if (
      getIndustriesFromLocalStorage().length == 0 &&
      getCompaniesFromLocalStorage().includes(filter_nombre_empresa)
    ) {
      activate = true;
    } else {
      activate = false;
    }
  }

  return activate;
}

function cardBelongsFilter(leadCustomData) {
  let activateSound = false;
  let selectedFilters = localStorage.getItem('customFilters')
    ? JSON.parse(localStorage.getItem('customFilters'))
    : {};

  let fields = Object.keys(selectedFilters);
  // convirtiendo nulls a undefined (normalizando para filtrar correctamente "otros")
  fields.forEach((field) => {
    selectedFilters[field] = selectedFilters[field].map((value) =>
      value === null ? undefined : value,
    );
  });
  let hasFieldValues = fields.some(
    (field) => selectedFilters[field].length > 0,
  );
  if (fields.length > 0 && hasFieldValues) {
    let satisfy = false;
    let satisfies = []; // todos deben ser true
    for (const field of fields) {
      if (selectedFilters[field].includes(leadCustomData[field])) {
        satisfies.push(true);
      } else if (selectedFilters[field].length > 0) {
        satisfies.push(false);
      }
    }
    if (satisfies.every((el) => el) && satisfies.length > 0) {
      satisfy = true;
    } else {
      satisfy = false;
    }
    satisfies = [];
    activateSound = satisfy;
  } else {
    activateSound = true;
  }
  return activateSound;
}

export default socket;
