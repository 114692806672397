// import trae from "trae";
import axios from "axios";
import environment from "./environment";
import store from "@/store/store";
import { localStorageGetItem } from "@/utils/utils";
import { changeAgentStatus } from "./utils/utils";

const dashboard_api = axios.create({
  baseURL: environment.url_env,
  headers: {
    // 'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

// axios.defaults.baseURL = environment.url_env;
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

dashboard_api.interceptors.request.use(
  (config) => {
    const urlsExcludedForBearerHeader = [
      '/auth/sign_in',
      '/auth',
      '/password/forgot',
      '/password/reset',
    ];
    const bot = ['/bot'];

    if (!bot.includes(config.url)) {
      dashboard_api.defaults.headers['Content-Type'] =
        'application/json';
    }

    if (urlsExcludedForBearerHeader.indexOf(config.url) === -1) {
      config.headers['access-token'] =
        localStorageGetItem('token') || store.state.authModule['access-token'];
      config.headers['token-type'] =
        localStorageGetItem('tokenType') ||
        store.state.authModule['token-type'];
      config.headers.client =
        localStorageGetItem('client') || store.state.authModule.client;
      config.headers.expiry =
        localStorageGetItem('expiry') || store.state.authModule.expiry;
      config.headers.uid =
        localStorageGetItem('uid') || store.state.authModule.uid;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
dashboard_api.interceptors.response.use(
  (response) =>
    // Do something with response data
    // Checks if app is being used in mobile
    // if (
    //   response.config.url !== `${process.env.VUE_APP_API_URL}/token` &&
    //   response.config.url !== `${window.location.origin}/version.json`
    // ) {
    //   //   checkForUpdates();
    //   checkIfTokenNeedsRefresh();
    // }
    response,
  async (error) => {
    console.log('aaaaa', error);
    // esto hace que si vence el token, se redireccione al login
    if (error.response.status === 401) {
      await changeAgentStatus("close_session")
      store.dispatch('logout');
      store.commit('cleanRegisterData');
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

export default dashboard_api;
