var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selected_bot)?_c('section',{key:_vm.logged,staticClass:"main-buttons"},[_c('div',{staticClass:"button-bots desktop"},[_c('div',{staticClass:"bots-box"},[(_vm.scrollPoint > 0)?_c('span',{staticClass:"i-btn py-1 px-2 ml-3 mr-3",on:{"click":function($event){return _vm.scrolltoBots('left')}}},[_vm._v(_vm._s(`<-`))]):_vm._e(),_c('div',{ref:"total_width",class:_vm.manyBots ? 'slide_box' : 'bots-box',attrs:{"id":"slide_box"}},[_vm._l((_vm.ownerBots),function(bot,index){return _c('span',{key:index,class:{
            'text-state text-state-md': true,
            selected: bot.id == _vm.selected_bot.id,
            desactived: bot.id == _vm.selected_bot.id && bot.activated == false,
          },on:{"click":function($event){return _vm.selectedBot(bot, 'button')}}},[_vm._v(_vm._s(bot.bot_name)),(bot.id == _vm.selected_bot.id && bot.activated == false)?_c('i',{staticClass:"fas fa-times",on:{"click":_vm.deleteChatbot}}):_vm._e()])}),_c('router-link',{staticClass:"btn-dash btn-dash-sm my-2 mr-3",attrs:{"to":"/chatbot/new"}},[_vm._v("+")])],2),(_vm.manyBots && _vm.scrollPoint < _vm.setTotalWidth)?_c('span',{staticClass:"i-btn py-1 px-2 mr-3",on:{"click":function($event){return _vm.scrolltoBots('right')}}},[_vm._v(_vm._s(`->`))]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center"},[(
          _vm.ecommerceAccount.show_bar == 'true' ||
          _vm.ecommerceAccount.show_bar == true
        )?_c('div',{staticClass:"progress_bar"},[_c('span',{staticClass:"text_xs mb-0"},[_vm._v("Cargando productos.. ")]),_c('progress',{class:{ done: _vm.ecommerceAccount.upload_progress >= 100 },attrs:{"title":"Cargando productos..","id":"progress_products","max":"100"},domProps:{"value":_vm.ecommerceAccount.upload_progress}})]):_vm._e(),(
          _vm.ecommerceAccount.show_done_bar == 'true' ||
          _vm.ecommerceAccount.show_done_bar == true
        )?_c('FadeTransition',{attrs:{"duration":200}},[_c('span',{staticClass:"text-state text-state-md mx-0 done",on:{"click":_vm.productsReady}},[_vm._v("Productos cargados")])]):_vm._e(),_c('UserStatus'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkCurrentView === 'chatbot-preview'),expression:"checkCurrentView === 'chatbot-preview'"}],staticClass:"text-state text-state-md desktop ml-3 mr-0",on:{"click":function($event){return _vm.copyPreviewLink()}}},[_c('span',{staticClass:"fas fa-clone mr-2"}),_vm._v(" Copiar link ")]),(_vm.user_role?.length > 0 && _vm.user_role != 'agente')?_c('router-link',{staticClass:"text-state text-state-md desktop mr-0 ml-3",attrs:{"to":"/preview"}},[_c('span',{staticClass:"i-base i-st i-chatbot mr-2"}),_vm._v("Previsualizar ")]):_vm._e(),(_vm.show_assigned_button)?_c('RolMenu',{attrs:{"mobile":false}}):_vm._e(),(_vm.showNotificationsFilter)?_c('div',{staticClass:"notificacion_box p-relative"},[(_vm.notification_bullet)?_c('span',{staticClass:"bullet"}):_vm._e(),_c('span',{class:{'i-btn i-btn-sm fas fa-bell mr-0 ml-3': true, active: _vm.notification_bullet},on:{"click":function($event){(_vm.show_notifications = !_vm.show_notifications)}}}),(_vm.selected_bot?.id)?_c('NotificacionMenu',{key:_vm.selected_bot.id,attrs:{"show":_vm.show_notifications}}):_vm._e()],1):_vm._e()],1)]),_c('div',{class:{
      'menu-mobile mobile': true,
      'no-icon-view': !_vm.show_assigned_button,
    }},[_c('span',{staticClass:"i-btn i-menu",on:{"click":function($event){return _vm.setShowSidebarMobile(!_vm.showSidebarMobile)}}}),(_vm.ownerBots)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mobile_selected_bot),expression:"mobile_selected_bot"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.mobile_selected_bot=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.selectedBot(_vm.mobile_selected_bot, 'button')}]}},_vm._l((_vm.ownerBots),function(bot,index){return _c('option',{key:index,domProps:{"value":bot}},[_vm._v(" "+_vm._s(bot.bot_name)+" ")])}),0):_vm._e(),(_vm.show_assigned_button === true)?_c('RolMenu',{attrs:{"mobile":true}}):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }