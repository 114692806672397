<template>
  <section>
    <SpinnerLoading v-show="!selected_bot?.id" />
    <FadeTransition v-show="selected_bot?.id" :duration="200">
      <div :class="{ layout: true, 'no-txt-select': show_video_page }" id="layout">
        <div class="item-1">
          <SideBar />
        </div>
        <div class="item-2">
          <DaysLeft v-if="selected_bot" />
          <ButtonBots />
        </div>
        <SpinnerLoading v-if="loading || global_loading" />
        <div v-else :class="{ 'item-3': true, 'pt-5': spaceTop, 'center-view': centeredViews }">
          <router-view :key="selected_bot.id" v-if="selected_bot?.id" />
        </div>
        <VideoPage
          v-if="selected_video"
          v-show="show_video_page"
          :video="selected_video"
          :is_show="show_video_page"
          @close="show_video_page = false, setSelectedVideo({}), setMoveEjeX(470)"
          @update:x="handleUpdateX"
          @update:y="handleUpdateY"
          :x="x"
          :y="y"
        />
        <ValidatePermissionRoles
          v-if="role_permission.module_permissions?.length > 0"
          :view_name="$route.name"
          :key="$route.name"
        />
      </div>
    </FadeTransition>
  </section>
</template>

<script>
import ButtonBots from '@/components/ButtonBots';
import { FadeTransition } from 'vue2-transitions';
import { mapMutations, mapState } from 'vuex';
import SideBar from '@/components/SidebarPlugin/SideBar.vue';
import DaysLeft from '@/components/DaysLeft';
import dashboard_api from '@/dashboard_api';
import {
  addCustomScript,
  hasActiveSoundNotifications,
  waitForAudioRunning,
  insertSoporteBotDatabot,
} from '@/utils/utils';
import socket from '@/plugins/sockets';
import VideoPage from "@/components/VideoPage";
import axios from 'axios';
import { localStorageSetItem, localStorageGetItem } from '../utils/utils';
import { changeAgentStatus } from '../utils/utils';

export default {
  components: {
    ButtonBots,
    FadeTransition,
    DaysLeft,
    SideBar,
    VideoPage
  },
  data() {
    return {
      loading: false,
      isSoundRunning: false,
      show_video_page: false,
      x: 0,
      y: 0
    };
  },
  created() {
    this.oneSignalPlayerId();
  },
  async mounted() {
    this.changeWebhookQA(); // Para probar registro en qa

    if (this.$route.query.seccion) {
      this.scrollTo(2000);
    };
    socket.on('PUSH_DATA', data => {
      data.payload.user_online = true;

      // Validacion: notificacion llegará cuando la conversacion sea con el bot seleccionado
      if (data.botId == this.selected_bot.id) {
        console.log('PUSH_DATA layout', data);
        // Valida que no llegue la notificacion si ya fue calificada
        if (data.payload.room_token != this.roomToken) {
          // Valida que llegue notificacion al owner y agente segun categoria
          if (
            !this.agentAccount ||
            !this.agentAccount.category ||
            (this.agentAccount.category &&
              this.agentAccount.category.includes(data.payload.category))
          ) {
            this.$swal({
              toast: true,
              html: `<span><span class="notification_number mr-2">1</span> Nueva conversación</span>`,
              position: 'top-end',
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: false,
            });
            this.setNewMessage(true);
            this.setPushData(data);
          };
        };

        // TODO Revisar validaciones ->
        // if (data.id && this.user.botIds.includes(data.botId)) {
        //   let dlive_id = dlive_id != 0 ? dlive_id : data.id;
        //   // Guardando evento en store param 'push_data' para usar watch en chat_cards y agregar la tarjeta
        //   // this.setPushData(data);
        // }
        // if (typeof data.botId === 'string') data.botId = parseInt(data.botId);
      };
    });
    const self = this;
    // esta parte de codigo corrige bug notificaciones de sonido no suenan fuera de databot
    if (hasActiveSoundNotifications() && self.isSoundRunning == false) {
      waitForAudioRunning();
      self.isSoundRunning = true;
    };
    // este if actualiza la ventana del bot cuando se entra a dashboard
    if (self.$route.path == '/dashboard') {
      window.onload = function () {
        insertSoporteBotDatabot();
      };
    };
  },
  computed: {
    ...mapState([
      'user',
      'selected_bot',
      'skill_template',
      'selectedChat',
      'roomToken',
      'agentAccount',
      'templates',
      'selected_video',
      'role_permission',
      'checkoutData',
      'view_permissions',
      'global_loading',
      'providers',
      'notification_socket',
      'userBot',
      'user_role',
      'agent_status'      
    ]),
    bot_id() {
      return this.selected_bot.id;
    },
    spaceTop() {
      return this.view_permissions.read == true &&
        (
          this.$route.path != '/databot-live' &&
          this.$route.path != '/databot-live-2' &&
          this.$route.path != '/preview' &&
          this.$route.path != '/clients' &&
          this.$route.path != '/tutoriales' &&
          this.$route.path != '/documento-conocimiento' &&
          this.$route.path != '/tickets' &&
          this.$route.path != '/follow-incident' &&
          this.$route.path != '/metrics' &&
          this.$route.path != '/dashboard'
        );
    },
    centeredViews() {
      return (
        this.$route.name == 'agentes'
      )
    }
  },
  watch: {
    selected_video(val) {
      if (val.id) this.show_video_page = true;
      else {
        // No seleccionar texto cuando arrastre el cursor para resize
        let item = document.getElementById("layout");
        return item.classList.remove("no-txt-select");
      }
    },
    selected_bot(val) {
      // Verificar si la pestaña es nueva
      socket.emit('REGISTER_AGENT_STATUS', { userId: this.user.id, botId: val?.id, user_role: this.user_role, status: this.agent_status });
      this.getIntegrationsByBot(val);
      this.setShowClientInfo(false);
      // code for bots with skill base
      if (val?.has_skill_base) this.getSkillTemplate(val);
    },
    $route: {
      immediate: true,
      handler(to, from) {
        this.scrollTo(2000);
        this.hiddeBotView(to);
        this.setShowSidebarMobile(false);
        if (to.path != '/databot-live') {
          // Reset dlive stores vals
          this.setCurrentRoomToken(null);
          this.setSelectedChat(null);
          this.setShowClientInfo(false);
        }
      },
    },
  },
  methods: {
    ...mapMutations([
      'logout',
      'setNewMessage',
      'setPushData',
      'setShowClientInfo',
      'setSelectedChat',
      'setRouterSkillBase',
      'setCurrentRoomToken',
      'setTemplateValue',
      'setSelectedVideo',
      'setMoveEjeX',
      'setProviders',
      'setShowSidebarMobile'
    ]),
    async getIntegrationsByBot(bot) {
      if (bot?.id) {
        try {
          let integrations = (
            await dashboard_api.get(`/user_credentials/search_for_bot/${bot.id}`, { headers: { BOT_TOKEN: bot.token, BOT_ID: bot.id } })
          ).data;
  
          this.setProviders(integrations);
        } catch (error) {
          console.log("getIntegrationsByBot error->", error);
          this.setProviders([]);
        }
      }
    },
    async changeWebhookQA() {
      // Only QA
      if (window.location.origin == 'https://dashboard-front-qa.herokuapp.com') {
        let uploadSkill = await this.$store.dispatch(
            'dtClientSkillsModule/skillUploadLLM',
            { bot_id: this.selected_bot.id },
          );
        let data = JSON.stringify({
          "webhook_url": "https://chatbot-front-qa.herokuapp.com/webhook"
        });
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://rasaia2.databot.cl/api/assistants/${uploadSkill.payload.assistant_id}/set_webhook`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    handleUpdateX(newX) {
      this.x = newX;
    },
    handleUpdateY(newY) {
      this.y = newY;
    },
    hiddeBotView(route) {
      let bot_div = document.getElementById('chat_box_identifier');

      if (bot_div) {
        if (route.name == "databot-live") bot_div.classList.add("not_here");
        else if (route.name == "databot-live-2") bot_div.classList.add("not_here");
        else if (route.name == "crm-clients") bot_div.classList.add("not_here");
        else bot_div.classList.remove("not_here");
      }
    },
    oneSignalPlayerId() {
      const self = this;
      let push_subscriber;
      OneSignal.push(() => {
        OneSignal.isPushNotificationsEnabled(isEnabled => {
          dashboard_api
            .get(`/push_subscribers/by_user_id/${self.user.id}`)
            .then(response => {
              push_subscriber = response.data;

              if (isEnabled) {
                // console.log("Push notifications are enabled!");
                OneSignal.getUserId(userId => {
                  if (push_subscriber) {
                    if (push_subscriber.sid != userId) {
                      dashboard_api.put(
                        `/push_subscribers/${push_subscriber.id}`,
                        {
                          push_subscriber: {
                            sid: userId,
                            active: true,
                          },
                        },
                      );
                    } else {
                      dashboard_api.put(
                        `/push_subscribers/${push_subscriber.id}`,
                        {
                          push_subscriber: {
                            active: true,
                          },
                        },
                      );
                    }
                  } else {
                    dashboard_api
                      .post('/push_subscribers', {
                        push_subscriber: {
                          user_id: self.user.id,
                          sid: userId,
                          active: true,
                        },
                      })
                      .then(result => console.log(result))
                      .catch(error => console.log('error:'));
                  }
                  // Make a POST call to your server with the user ID
                  // Mixpanel Example
                  // mixpanel.people.set({ $onesignal_user_id: userId });
                });
              } else {
                console.log('Push notifications are not enabled yet.');

                dashboard_api
                  .put(`/push_subscribers/${push_subscriber.id}`, {
                    push_subscriber: {
                      active: false,
                    },
                  })
                  .then(result => console.log(result))
                  .catch(error => console.log('error:'));
              }
            })
            .catch(error => console.log('error:'));
        });
      });
    },

    // JUST FOR BOTS WITH SKILL BASE
    async getTemplate() {
      if (this.selected_bot.has_skill_base) {
        let data = (
          await this.$store.dispatch(
            'dtSectionsModule/listByTemplateName',
            this.skill_template.value,
          )
        ).sectionsFront;
        if (data) {
          let skill_base = data.map(el => ({
            name: el.section_ui_name,
            path: `/chatbot-center/skill-base/${el.section_ui_name
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .split(' ')
              .join('-')}`,
            original_name: el.section_name,
            bot_id: this.selected_bot.id,
          }));
          this.setRouterSkillBase(skill_base);
        };
      };
    },
    async getSkillTemplate(bot) {
      let selectedTemplate = {};

      if (bot.has_skill_base) {
        if (bot.skill_base_template == 'ecommerce') selectedTemplate = this.templates.find( el => el.name == 'ecommerce' );
        else if (bot.skill_base_template == 'generica') selectedTemplate = this.templates.find( el => el.name == 'generica');

        // Actualizando value del template en store
        this.setTemplateValue(selectedTemplate);
        // console.log("selectedTemplate--->", selectedTemplate.value);
        let template = this.skill_template.value;

        await Promise.all([
          this.$store.dispatch('dtClientIntentsModule/intentComplete', {
            template,
            bot_id: bot.id,
          }),
          this.$store.dispatch('dtClientNodesModule/nodeComplete', {
            template,
            bot_id: bot.id,
          }),
          this.$store.dispatch('dtClientEntitiesModule/entityCopyTemplate', {
            template,
            bot_id: bot.id,
          }),
        ]);
        this.getTemplate();
      }
    },
    // smooth scroll
    scrollTo(millis) {
      this.$scrollTo(`#${this.$route.query.seccion}`, millis);
    },
  },
  beforeDestroy() {
    socket.removeListener('PUSH_DATA');
  },
};
</script>

<style lang="scss">
.swal2-toast-shown .swal2-container.swal2-top-end {
  padding: 1rem;
}
.swal2-popup.swal2-toast {
  padding: 0.75rem 1rem;
  box-shadow: 5px 5px 20px #deedff;
}
</style>

<style lang="scss" scoped>
.layout {
  position: relative;
  display: grid;
  grid-template-columns: 65px 1fr;
  grid-template-rows: 1fr;
  transition: 0.5s;
  height: 100vh;
  overflow: hidden;
  background-color: #fafafa;

  .item-1 {
    grid-column: 1;
    grid-row: 1 / span 2;

    @media (max-width: 640px) {
      grid-column: 0;
      visibility: hidden;
      grid-row: 0;

      &:not(.btn-menu) {
        visibility: visible;
      }
    }
  }
  .item-2 {
    position: relative;
    grid-column: 2;
    grid-row: 1;
    height: fit-content;
    margin: 0;
    border-bottom: 1px solid #e8e8e8;

    @media (max-width: 1024px) {
      grid-column: 1 / span 2;
    }
  }
  .item-3 {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    height: calc(100vh - 70px);
    overflow-x: hidden;

    @media (max-width: 1024px) {
      grid-column: 1 / span 2;
    }
  }
  .center-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
